import React, { createRef, useState, useEffect } from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { InView } from 'react-intersection-observer'
import styled from 'styled-components'
import AnimatedMidBox from '../../components/AnimatedMidBox'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { SocialMediaLinks } from '../../utils'

const twConfig = resolveConfig(tailwindConfig)

const EmbedSection = styled.div`
    position: relative;
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: -5rem;
    overflow: hidden;    
    height: 620px;
    background-color: ${twConfig.theme.colors.pattensblue};
    @media (min-width: ${twConfig.theme.screens.md}) {
        height: 1300px;
    }
`

const VideoContainer = styled.div`
    margin: auto;
    width: 90%;
    height: auto;
    max-width: 1080px;
`

const ItemContainer = styled.button`
    position: relative;
    margin: 1rem;
    width: 140px;
    cursor: pointer;
    @media (min-width: ${twConfig.theme.screens.md}) {
        width: 200px;
    }
    @media (min-width: ${twConfig.theme.screens.lg}) {
        width: 360px;
    }
`

const YOUTUBE_URL = "https://www.youtube.com/embed/cC6Sc3A5WZU?autoplay=1&rel=0"

const Advocacy = ({ pageContext, location }) => {
    const page = pageContext.page
    const refMidbox = createRef()
    const [selected, setSelected] = useState(null)

    const data = useStaticQuery(graphql`
    query {
        allWpAdvocacyVideo {
            nodes {
                databaseId
                ourAdvocacyFields {
                    title
                    videoUrl
                    thumbnail {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(webpOptions: {quality: 50}, transformOptions: {fit: COVER})
                            }
                        }
                    }
                }
            }
        } 
        favicon: file(relativePath: {eq: "logo-w431.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 431
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
    }
    `)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('keydown', (e) => {
                if (e.key === "Escape") setSelected(null)
            })
        }
    }, [])

    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>
            
            <div className="relative pt-10 text-center md:pt-20 lg:pt-32 bg-pattensblue">
                <h1 className="relative z-40 inline-block py-4 mx-auto text-lg tracking-wider text-white uppercase rounded-full md:text-4xl bg-seance px-7">
                    Our Advocacy
                </h1>
            </div>

            <EmbedSection>
                <AnimatedMidBox ref={ refMidbox } />
                <div className="absolute flex w-full h-full transform -translate-x-1/2 -translate-y-1/2 left-2/4" style={{ top:'54%' }}>
                    <VideoContainer>
                        <InView as="div" className="m-auto" onChange={ (inView) => refMidbox.current.start(inView) }>
                            <div className="aspect-w-16 aspect-h-9">
                                <iframe title="Do it Right! Do it with TRUST!" src={YOUTUBE_URL} frameBorder="0" allow="autoplay; fullscreen" />
                            </div>
                        </InView>
                    </VideoContainer>
                </div>
            </EmbedSection>

            <section className="pb-20 bg-pattensblue">
                <div className="container flex flex-row flex-wrap items-center justify-center mx-auto">
                    {data.allWpAdvocacyVideo.nodes && data.allWpAdvocacyVideo.nodes.map((node) => {
                        return (
                            <ItemContainer key={ node.databaseId } className="z-40 transition-shadow hover:shadow-2xl" 
                                onClick={ (e) => setSelected({ title: node.ourAdvocacyFields.title, videoUrl: node.ourAdvocacyFields.videoUrl }) } 
                            >
                                <div className="absolute left-0 z-10 flex w-full h-full p-1 transition-opacity bg-purple-500 opacity-0 bg-opacity-80 hover:opacity-100">
                                    <p className="m-auto text-sm text-center text-white md:text-xl">{ node.ourAdvocacyFields.title }</p>
                                </div>
                                <GatsbyImage className="w-full h-full" 
                                    image={getImage(node.ourAdvocacyFields.thumbnail.localFile)} 
                                    alt={node.ourAdvocacyFields.thumbnail.altText} />
                            </ItemContainer>
                        )
                    })}
                </div>
            </section>

            {!!selected ? (
                <div 
                    className="fixed inset-0 flex bg-black bg-opacity-80"
                    style={{ zIndex:99999999999 }}
                    onClick={ (e) => {
                        e.preventDefault()
                        if (e.currentTarget === e.target) setSelected(null)
                    }} 
                    onKeyPress={(e) => {
                        if (e.key === "Escape") setSelected(null)
                    }} 
                    tabIndex={0} 
                    role="button"
                >
                    <div className="m-auto " style={{ width:'95%', maxWidth:'1080px' }}>
                        <div className="w-full bg-black">
                            <h4 className="p-1 text-xl tracking-wider text-center text-white lg:text-4xl lg:py-4 bg-purpleheart">
                                {selected.title}
                            </h4>
                            <div className="aspect-w-16 aspect-h-9">
                                <iframe title={ selected.title } src={ selected.videoUrl } frameBorder="0" allow="autoplay; fullscreen"/>
                            </div>
                        </div>
                        <div className="flex pt-5">
                            <FontAwesomeIcon 
                                icon={ faTimes } 
                                style={{ width:'56px', height:'56px' }}
                                className="p-2 m-auto text-white bg-black rounded-full cursor-pointer opacity-80 bg-opacity-60" 
                                onClick={ (e) => {
                                    e.preventDefault()
                                    setSelected(null)
                                }}/>
                        </div>
                    </div>
                </div>
            ):null}

        </Layout>
    )
}

export default Advocacy